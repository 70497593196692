<template>
    <div>
        <div class="container">
            <div v-if="company && company.data">
                <page-title :page-title="company.data.name " :borderBottom="false"></page-title>
                <div class="tabbable clients-show">
                    <ul class="nav nav-tabs mb-4">
                        <li class="nav-item">
                            <router-link :to="`/my-account/${$route.params.id}/general`" class="nav-link"
                                         :class="{'active': $router.currentRoute.name == 'general'}">
                                {{ translate('General', 'account') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="`/my-account/${$route.params.id}/billing`" class="nav-link"
                                         :class="{'active': $router.currentRoute.name == 'billing'}">
                                {{ translate('Billing', 'account') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="`/my-account/${$route.params.id}/members`" class="nav-link"
                                         :class="{'active': $router.currentRoute.name == 'members'}">
                                {{ translate('Members', 'account') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="`/my-account/${$route.params.id}/subscriptions`" class="nav-link"
                                         :class="{'active': $router.currentRoute.name == 'subscriptions'}">
                                {{ translate('Subscriptions', 'account') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="`/my-account/${$route.params.id}/payments`" class="nav-link"
                                         :class="{'active': $router.currentRoute.name == 'my-payments'}">
                                {{ translate('Payments', 'account') }}
                            </router-link>
                        </li>
                    </ul>
                </div>

                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import ErpModel from '@/modules/erp_framework/services/ErpModel'


export default {
    name: 'ClientsShow',
    data() {
        return {
            company: null,
        }
    },
    async mounted() {
        this.$store.state.system.isLoading = true
        this.company = await ErpModel.find('companies', this.$route.params.id)
        this.$store.state.system.isLoading = false
    },
}
</script>

<style scoped lang="scss">
	.tabbable.clients-show .nav-link:not(.active) {
		color: #000;

		&:hover {
			color: #ff0553;
		}
	}

	.tabbable.clients-show .nav-link.active {
		color: #ff0553;
		border-color: transparent transparent #ff0553;
	}
</style>
